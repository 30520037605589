.get-answer-badge{
    /* background-color: #FFECEC; */
    /* color: #36B864; */
    background-color: #FC1173;
    color: #FFFFFF;
    font-weight: 900;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    top: -15px;
    right: -15px;
}

/* -----------------------------------add to query--page------------------------------- */


  
  